const { mapState } = require('vuex')
module.exports = {
    computed: mapState({
        count: state => state.count,

        countAlias: 'count',

        countPlusLocalState (state) {
            return state.count + this.localCount
        }
    }),
    // baseUrl: 'http://localhost:8089/',
    // baseUrl: 'https://iso.vestatest.ir/',
    baseUrl: 'https://isot.vestatest.ir/',

    // serverBaseUrl: 'https://iso.vestatest.ir'
    serverBaseUrl: 'https://isot.vestatest.ir'
  }