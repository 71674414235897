export default {
  'general': {
    'title': ' برنا طب ایساتیس',
    'site_title': 'برنا طب ایساتیس',
    'products_category': 'دسته‌بندی محصولات',
    'vendor': 'فروشنده',
    'see_all': 'مشاهده همه',
    'all': 'همه',
    'blog': 'بلاگ',
    'welcome': 'خوش آمدید',
    'store_address': 'یزد',
    'in_person_order': 'سفارش حضوری',
    'phone_order': 'سفارش تلفنی',
    'online_order': 'سفارش اینترنتی',
    'store_official_information': {
      'legal_person_title': 'برنا طب ایساتیس',
      'economic_number': '000000',
      'registration_or_national_number': '000000',
      '10_digits_postal_code': ' ',
      'phone_or_fax_number': '  ',
      'full_address': 'یزد'
    }
  },
  'template': {
    'client': {
      'header': {
        'login_register': 'ورود / ثبت نام',
        'products_categories': 'دسته‌بندی محصولات',
        '3d_room': 'گالری',
        'about':'درباره ما',
        'call':'تماس با ما',
        'blog': 'بلاگ'
      },

      'mega_menu': {
        'all_items_of_this_category': 'همه موارد این دسته'
      },

      'footer': {
        'phon_number': '03537331974    -   03537332958',
        'introduction': 'یزد ',
        'how_to_buy': 'راهنمای خرید',
        'terms_and_conditions': 'شرایط استفاده',
        'faq': 'پرسش‌های متداول',
        'blog': 'بلاگ',
        'call':'تماس:',
      }
    },
    'header': {
      'login': 'ورود',
      'register': 'ثبت نام',
      'admin_panel': 'پنل مدیریت',
      'profile': 'پروفایل',
      'wallet': 'کیف پول',
      'my_orders': 'سفارش‌های من',
      'logout': 'خروج',
      'menu': 'منو'
    },
    'sidebar': {
      'home': 'خانه',
      'dashboard': 'میز کار',
      'users': {
        'title': 'کاربران',
        'roles_and_permissions': 'نقش‌ها و دسترسی‌ها',
        'colleagues': 'همکاران',
        'users_list': 'لیست کاربران',
        'add_user': 'ایجاد کاربر'
      },
      'products': {
        'title': 'دپو',
        'units': 'واحدها',
        'categories': 'دسته‌بندی‌ها',
        'tags': 'برچسب‌ها',
        'attributes': 'مشخصات',
        'products_list': 'لیست دپو',
        'add_product': 'مدیریت محصول',
        'add_pattern': 'مدیریت طرح پارچه',
        'add_tar_pood': 'مدیریت تاروپود',
        'add_material': 'مدیریت جنس پارچه',
        'add_unit': 'مدیریت سایز',
        'add_pood_material': 'مدیریت جنس پود'

      },
      'warehouse': {
        'title': 'انبارها',
        'warehouse_list': 'لیست  انبارها',
        'add_warehouse': 'ایجاد انبار',
        'stock_warehouse': 'موجودی انبار',
        'search_in_stock_warehouse': 'جستجو کد انبار',

      },
      'workshop': {
        'title': 'کارگاه ها',
        'add_workshop': 'مدیریت کارگاه',

      },
      'machine': {
        'title': 'دستگاه ها',
        'add_machine': 'مدیریت دستگاه',
        'machine_list': 'خروجی دستگاه ها',
        'producing_list': 'در حال تولید',

      },
      'productions': {
        'title': 'تولید',
        'producing_product': 'تولید محصول',
        'productions_report': 'گزارش تولیدها'
      },
      'comments': {
        'title': 'نظرات',
        'submitted_comments': 'نظرات ثبت شده',
        'reported_comments': 'نظرات گزارش شده'
      },
      'people': {
        'title': 'مشتری ها',
        'manage_people': 'مدیریت مشتری',
        'people_financial_report': 'گردش مالی اشخاص'
      },
      'orders': {
        'title': 'سفارشات',
        'delivery_methods': 'روش‌های تحویل',
        'delivery_timespans': 'بازه‌های زمانی تحویل',
        'submit_order': 'ثبت سفارش',
        'orders_list': 'لیست سفارشات',
        'process_orders': 'پردازش سفارشات'
      },
      'discounts': {
        'title': 'کدهای تخفیف',
        'discounts_management': 'مدیریت کدهای تخفیف'
      },
      'invoices': {
        'title': 'فاکتورها',
        'buy_invoice': 'فاکتور خرید',
        'return_buy_invoice': 'فاکتور بازگشت خرید',
        'sell_invoice': 'فاکتور فروش',
        'return_sell_invoice': 'فاکتور بازگشت فروش',
        'excluded_invoice': 'فاکتور معدوم شده',
        'pre_invoice': 'پیش فاکتور',
        'invoices_report': 'گزارش فاکتورها',
        'official_invoices_report': 'گزارش فاکتورهای رسمی'
      },
      'payments': {
        'title': 'پرداخت‌ها',
        'pay_by_person': 'دریافت از شخص',
        'receive_by_person': 'پرداخت به شخص',
        'payments_report': 'گزارش پرداخت‌ها'
      },
      'reports': {
        'title': 'گزارشات',
        'users_checkouts': 'تسویه حساب کاربران',
        'balances_report':'بدهکار/ بستانکار',
        'buy_and_sell': 'خرید و فروش',
        'general_buy_and_sell': 'فاکتور تجمیعی',
        'logs': 'لاگ‌ها'
      }
    }
  },
  'links': {
    'main_page': 'صفحه اصلی',
    'login': 'ورود به سایت',
    'my_orders': 'سفارش‌های من'
  },
  'buttons': {
    'login': 'ورود',
    'register': 'ثبت نام',
    'profile': 'پروفایل',
    'logout': 'خروج',
    'print_factor':'پرینت حواله',
    'send_verification_code': 'ارسال کد تایید',
    'resend_verification_code': 'ارسال مجدد کد',
    'verify': 'تایید',
    'finish_registration': 'تکمیل ثبت نام',
    'change_password': 'تغییر رمز عبور',
    'yes': 'بله',
    'no': 'خیر',
    'start': 'شروع',
    'save': 'ذخیره',
    'save_and_publish': 'ذخیره و انتشار',
    'edit': 'ویرایش',
    'delete': 'حذف',
    'close': 'بستن',
    'return': 'بازگشت',
    'upload': 'آپلود',
    'copy': 'کپی کردن',
    'add_color':'افزودن رنگ',
    'add_pattern':'ایجاد طرح پارچه',
    'publish': 'انتشار',
    'unpublish': 'عدم انتشار',
    'preview': 'پیش نمایش',
    'products_list': 'لیست محصولات',
    'add_to_cart': 'افزودن به سبد خرید',
    'add_to_warehouse': 'افزودن به انبار',
    'proceed_shopping': 'ادامه فرآیند خرید',
    'add_all_to_cart': 'افزودن همه به سبد خرید',
    'increase_credit': 'افزایش موجودی',
    'request_checkout': 'درخواست تسویه',
    'add_new_account': 'ایجاد حساب جدید',
    'submit_order': 'ثبت سفارش',
    'edit_order': 'ویرایش سفارش',
    'confirm_request': 'تایید درخواست',
    'reject_request': 'رد درخواست',
    'proceed_order': 'اقدام سفارش',
    'cancel_order': 'لغو سفارش',
    'submit_filters': 'اعمال فیلترها',
    'create_report': 'تهیه گزارش',
    'submit_new_comment': 'ثبت نظر جدید',
    'submit': 'ثبت',
    'add_form':'افزودن فرم',
    'submit_stock_product': 'ثبت موجودی محصول',
    'back_to_stock_product': 'برگشت به لیست موجودی انبار',
    'send': 'ارسال',
    'add_address': 'ایجاد آدرس جدید',
    'create_new_customer': 'ایجاد مشتری جدید',
    'create_new_receiver': 'ایجاد گیرنده جدید',
    'create_customer': 'ایجاد',
    'update_customer_information': 'ویرایش اطلاعات مشتری',
    'update_receiver_information': 'ویرایش اطلاعات گیرنده',
    'update_customer': 'ویرایش کاربر',
    'pay_order': 'پرداخت سفارش',
    'new_order': 'سفارش جدید',
    'pay': 'پرداخت',
    'pay_by_pos': 'پرداخت از طریق POS',
    'print': 'پرینت',
    'print_a5': 'پرینت A5',
    'orders_list': 'لیست سفارشات',
    'search': 'جستجو',
    'new_invoice': 'فاکتور جدید',
    'invoices_report': 'گزارش فاکتورها',
    'pay_invoice': 'پرداخت فاکتور',
    'apply_discount_code': 'اعمال کد تخفیف',
    'issue_official_invoice': 'صدور فاکتور رسمی',
    'print_official_invoice': 'پرینت فاکتور رسمی',
    'add_product':'ایجاد محصول',
    'add':'افزودن',
    'add_tar':'اضافه کردن تار',
    'add_pood':'اضافه کردن پود',
    'control': ' اقدام',
    'confirm':'تایید',
    'show':'نمایش',
    'delivered':'تحویل داده شده',
    'refresh_form':'حذف اطلاعات از فرم',
    'show_detail':'نمایش جزئیات محصول',
    'add_user': 'افزودن کاربر',
    'edit_user': 'ویرایش کاربر',
    'add_role': 'افزودن نقش',
    'edit_role': 'ویرایش نقش',
    'show_and_print' :'نمایش و پرینت',
    'back_to_orders':'برگشت به پردازش سفارشات',
    'action':'اقدام',
    'exit_warehouse':'خروج از انبار',
    'show_description':'نمایش موارد توضیح دار',
    'hide_description':'عدم نمایش موارد توضیح دار',
    'insert':'ایجاد',
  },
  'tooltips': {
    'login': 'ورود',
    'register': 'ثبت نام',
    'profile': 'پروفایل',
    'admin_panel': 'پنل مدیریت',
    'logout': 'خروج',
    'show': 'نمایش',
    'show_details': 'نمایش جزئیات',
    'details': ' جزئیات',
    'control': 'اقدام',
    'edit': 'ویرایش',
    'delete': 'حذف',
    'send_to_warehouse': 'افزودن به انبار',
    'show_stock_warehouse': 'نمایش موجودی انبار',
    'edit_stock_product': 'تغییر موجودی',
    'show_stock_product': 'اطلاعات و موجودی محصول ',
    'show_warehouse_product': 'اطلاعات محصول ',
    'ban': 'مسدود کردن',
    'unban': 'رفع مسدودی',
    'add': 'افزودن',
    'add_color': 'افزودن رنگ',
    'delete_color': 'حذف رنگ',
    'product_link': 'لینک محصول',
    'min_stock_alert': 'در حال اتمام موجودی',
    'product_preview': 'پیش نمایش محصول',
    'publish': 'انتشار',
    'unpublish': 'عدم انتشار',
    'upload': 'آپلود',
    'save': 'ذخیره',
    'cancel': 'لغو',
    'confirm': 'تایید',
    'reject': 'رد',
    'ad': 'تبلیغات',
    'cart': 'سبد خرید',
    'refresh_cart': 'به‌روزرسانی سبد خرید',
    'move_to_next_shopping_list': 'انتقال به لیست خرید بعدی',
    'delete_from_cart': 'حذف از سبد خرید',
    'move_to_cart': 'انتقال به سبد خرید',
    'delete_from_next_shopping_list': 'حذف از لیست خرید بعدی',
    'proceed': 'اقدام',
    'show_reply_message': 'نمایش متن پاسخ',
    'filters': 'فیلترها',
    'group_proceed': 'اقدام گروهی',
    'group_cancel': 'لغو گروهی',
    'close_report': 'بستن گزارش‌ها',
    'delete_comment': 'حذف نظر',
    'pay': 'پرداخت',
    'print': 'پرینت',
    'refresh': 'به‌روزرسانی',
    'submit_order': 'ثبت سفارش محصول',
    'information_for_official_invoice': 'اطلاعات برای صدور فاکتور رسمی'
  },
  'labels': {
    'calibration':'مدیریت کالیبراسیون ها',
    'final_result':'نتیجه نهایی:',
    'header_microbial':'آزمایش های میکروبی (سترونی)',
    'header_chemical_physical':'آزمایش های شیمیایی - فیزیکی',
    'enter_date':'تاریخ را وارد کنید ',
    'show':'اقدامات',
    'finished':'تمام شده',
    'not_finished':'تمام نشده',
    'select_uid':'uidرا انتخاب کنید',
    'select_lotNumber':'لات نامبر را انتخاب کنید',
    'tracing_by_uid':'شناسایی و ردیابی با UID',
    'new_sales_invoice':'ایجاد فاکتور فروش ',
    'new_PreSales_invoice':'ایجاد پیش فاکتور فروش ',
    'print_chart':'نمودار چاپ',
    'degazine_chart':'نمودار دگزین',
    'primary_packing_chart':'نمودار بسته بندی اولیه',
    'secondary_packing_chart':'نمودار بسته بندی ثانویه',
    'sterling_chart':'نمودار استریل',
    'silicon_chart':'نمودار مونتاژ',
    'injection_chart':'نمودار تزریق',
    'order_product':'نام محصول',
    'buyer':'خریدار',
    'order_date':'تاریخ سفارش',
    'order_code':'کد سفارش',
    'seller_signature':'مهرو امضا فروشنده',
    'buyer_signature': 'مهر و امضا خریدار',
    'lot_no':'LOT.NO',
    'lot_no-b':'LOT.<br>NO',
    'number_in_pack':'تعداد در کارتن',
    'number_pack':'تعداد  کارتن',
    'number_in_pack-b':'تعداد <br> در<br> کارتن',
    'number_pack-b':'تعداد <br> کارتن',
    'Unit_amount':'مبلغ واحد',
    'Unit_amount-b':'مبلغ <br>واحد',
    'discount_percent':'درصد تخفیف',
    'discount_percent-b':'درصد <br>تخفیف',
    'discount_cost':'مبلغ تخفیف',
    'discount_cost-b':'مبلغ<br> تخفیف',
    'final_cost':'مبلغ نهایی',
    'final_cost-b':'مبلغ<br> نهایی',
    'product_info':'شرح کالا',
    'product_info-b':'شرح <br>کالا',
    'IRC':'IRC',
    'total_amount':'مبلغ کل از',
    'total_amount-b':'مبلغ<br> کل از',
    'products_code':'کد کالا',
    'products_code-b':'کد  <br>کالا',
    'order':'ردیف',
    'product_details':'مشخصات کالا',
    'economic_code':'کد اقتصادی:',
    'National_ID':'شناسه ملی:',
    'telephone':'تلفن:',
    'id':'شناسه:',
    'id_header':'شناسه',
    'Trade_ID':'شناسه صنفی:',
    'Trade_ID_header':'شناسه صنفی',
    'Postal_code':'کد پستی:',
    'telephone_header':'تلفن',
    'economic_code_header':'کد اقتصادی',
    'National_ID_header':'شناسه ملی',
    'company_name':'نام شرکت:',
    'Buyer_details':'مشخصات خریدار',
    'Seller_details':'مشخصات فروشنده',
    'sales_invoice':'فاکتور فروش کالا',
    'sales_pre_invoice':'پیش فاکتور فروش کالا',
    'signature':'امضاء:',
    'signatures':'امضاء',
    'number_input_materials_previous_step':'شماره مواد ورودی از مرحله قبل',
    'number_Syringe':'تعداد سرنگ',
    'number_in_box':'تعداد در بسته',
    'The_entry_number_previous_step':'شماره ورودی مرحله قبل:',
    'The_entry_number_previous_step_material':'شماره مواد ورودی مرحله قبل (محصول مونتاژ شده):',
    'product_number_for_exit':'شماره محصول خروجی (لات نامبر و تاریخ)',
    'chamber_loader':'بارگیری کننده چمبر:',
    'approve_number_EO_gas':'شماره تایید گاز اتیلن اکساید:',
    'number_chamber_loader':'شماره بارگیری چمبر:',
    'unloadere_chamber':'تخلیه کننده چمبر:',
    'number_cartons':'تعداد کارتن',
    'product_type':'نوع محصول',
    'capsule_weight_before_loading':'وزن کپسول <br>قبل از بارگیری',
    'capsule_weight_after_loading':'وزن کپسول بعد<br> از بارگیری',
    'date_time_gassing':'تاریخ و ساعت گازگیری',
    'date_time_discharge':'تاریخ وساعت تخلیه',
    'Sterile_room_temperature':'دمای اتاق استریل 55 و<br> دمای مخزن آب 80 درجه می باشد.',
    'inside_chamber':'درون چمبر ویال میکروبی <br>و اندیکاتور شیمیایی گذاشته شد.',
    'wash_first_step':'شست وشوی مرحله اول  <br>انجام شد.',
    'wash_second_step':'شست وشوی مرحله دوم <br>انجام شد.',
    'wash_third_step':'شست و شوی مرحله سوم <br>انجام شد.',
    'inspection_status':'وضعیت بازرسی',
    'awaiting_inspection':'در انتظار بازرسی',
    'approve':'تایید',
    'reject':'رد (شماره فرم نا منطبق..............)',
    'warehouse_production':'انبار/تولید',
    'Signature':'محل امضا',
    'IssuingAssignment':'صدور حواله',
    'access':'دسترسی ها',
    'product_name':'نام محصول:',
    'lot_Number':'شماره لات:',
    'amount_lot_number':'مقدار لات:',
    'Number_of_chemical_samples':'تعداد نمونه شیمیایی:',
    'Number_of_physical_samples':'تعداد نمونه فیزیکی:',
    'Number_of_microbial_samples':'تعداد نمونه میکروبی:',
    'date_chemical_test':'تاریخ انجام آزمایش های شیمیایی:',
    'date_physical_test':'تاریخ انجام آزمایش های فیزیکی:',
    'date_microbial_test':'تاریخ انجام آزمایش های <br>سترونی(میکروبیولوژی):',
    'date_Sterilization_test':'تاریخ جوابدهی آزمون سترونی:',
    'header_test_result_form':'فرم نتایج آزمون ها',
    'header_identification_form':'فرم شناسایی و ردیابی',
    'PackingControlForm':'فرم کنترل بسته بندی',
    'test_name':'نام آزمایش',
    'acceptable_range':'حد مجاز',
    'test_result':'نتیجه آزمایش',
    'type_test':'نوع آزمایش',
    'product_title':'آیتم مورد بررسی',
    'review_item':'توضیحات',
    'conformity':'مطابقت دارد/ندارد',
    'control_result':'نتیجه کنترل',
    'type_degazin':'نوع',
    'uid':'uid',
    'mfg_date':'تاریخ تولید',
    'exp_date':'تاریخ انقضا',
    'height_tolerance':' تلرانس ارتفاع',
    'height':'ارتفاع',
    'diameter_tolerance':' تلرانس قطر',
    'diameter':'قطر',
    'thumb_case':'جا انگشتی',
    'thumb_case_tolerance':'تلرانس جا انگشتی',
    'start_time':'زمان شروع',
    'end_time':'زمان پایان',
    'effective_form':'فرم اثربخشی',
    'nominal_capacity':'ظرفیت اسمی',
    'depo_product':'محصول در جریان ساخت ',
    'final_product':'محصول در جریان ساخت / محصول نهایی ',
    'raw_material_product':'محصول در جریان ساخت ',
    'raw_material_form_title':'کنترل عملکرد مواد اولیه',
    'gasket_form_title':'کنترل فرآیند تزریق گسکت',
    'injection_form_title':'کنترل فرآیند تزریق ',
    'barrel_print_form_title':'کنترل فرآیند چاپ بارل ',
    'silicon_form_title':'کنترل فرآیند سیلیکون زنی و مونتاژ ',
    'non_compliance_form':'کنترل اقلام نامنطبق',
    'failure':'خرابی',
    'quantity':'تعداد',
    'lot_number':'لات نامبر',
    'UID':'UID',
    'packing_type':'نوع بسته بندی',
    'pack':'بسته ',
    'packets_qty':'تعداد بسته بندی',
    'abbreviation':'مخفف',
    'company':'نام شرکت',
    'page_number':'شماره فرم',
    'logo':'لوگو',
    'pin':'پین',
    'form_code':'کد فرم',
    'raw_material':'مواد اولیه',
    'supplier':'تامین کننده',
    'receiver_signature':'امضا گیرنده',
    'delivery_signature':'امضا تحویل دهنده',
    'driver_signature':'امضا راننده ',
    'print_detail':'اقلام فوق جمعاً به تعداد . . . . . . . . و متراژ . . . . . . . . . . . توسط آقای . . . . . . . . . . . . . با خودرو به شماره پلاک . . . . . . . . . . . . . . ارسال گردید.',
    'insert_value':'مقدار مورد نظر را وارد کنید',
    'mobile': 'شماره همراه',
    'insert_mobile': 'شماره همراه را وارد کنید',
    'user_name': 'نام کاربری',
    'insert_user_name': 'نام کاربری را وارد کنید',
    'password': 'رمز عبور را وارد کنید',
    'insert_password': 'رمز عبور',
    'password_confirmation': 'تایید رمز عبور',
    'insert_password_confirmation': ' رمز عبور را دوباره وارد کنید',
    'current_password': 'رمز عبور فعلی',
    'new_password': 'رمز عبور جدید',
    'new_password_confirmation': 'تایید رمز عبور جدید',
    'verification_code': 'کد پیامک شده را اینجا وارد کنید',
    'title': 'عنوان',
    'insert_title': 'عنوان مورد نظر خود را وارد کنید',
    'number_of_colors' : 'تعداد رنگ',
    'insert_number_of_colors' : 'تعداد رنگ را وارد کنید',
    'description': 'توضیحات',
    'insert_description': 'توضیحات را وارد کنید',
    'excerpt_description': 'توضیحات چکیده',
    'role': 'نقش',
    'name': 'نام و نام خانوادگی',
    'warehouse_name': 'نام انبار',
    'warehouse_type': 'نوع انبار',
    'workshop_type': 'نوع کارگاه',
    'machine_type': 'نوع دستگاه',
    'pattern_name': 'نام طرح',
    'insert_pattern_name': 'نام طرح را وارد کنید',
    'material_name': 'نام جنس',
    'material_type': 'جنس پارچه',
    'insert_material_type': ' جنس پارچه را وارد کنید',
    'unit_name': 'ابعاد',
    'insert_unit_name': 'ابعاد رو وارد کنید',
    'unit_description': 'قطعات',
    'insert_unit_description': 'قطعات را وارد کنید',
    'unit_azrib': 'ضریب',
    'insert_unit_azrib': 'ضریب را وارد کنید',
    'unit_size': 'سایز',
    'insert_unit_size': 'سایز را وارد کنید',
    'color_code': 'کد رنگ',
    'sort_color': 'رنگ زمینه',
    'pattern_code': 'کد نقشه',
    'insert_pattern_code': 'کد نقشه را وارد کنید',
    'first_name': 'نام',
    'last_name': 'نام خانوادگی',
    'gender': 'جنسیت',
    'male': 'آقا',
    'female': 'خانم',
    'birth_date': 'تاریخ تولد',
    'email': 'ایمیل',
    'enter_search_phrase': 'عبارت جستجو را وارد کنید',
    'required': 'اجباری',
    'optional': 'اختیاری',
    'special_offer': 'پیشنهاد ویژه',
    'price': 'قیمت',
    'price_for': 'قیمت برای {title}',
    'onsale': 'تخفیف',
    'onsale_price': 'قیمت با تخفیف',
    'unit_price': 'قیمت واحد',
    'average_sell': 'قیمت میانگین',
    'unit_onsale_price': 'قیمت واحد با تخفیف',
    'total_price': 'قیمت کل',
    'total_money_amount': 'مبلغ کل',
    'products_price_without_discount': 'قیمت کالاها بدون تخفیف',
    'delivery_cost': 'هزینه ارسال',
    'total_summation': 'جمع کل',
    'number': 'عدد',
    'meter': 'متر',
    'rials': 'ریال',
    'without_charge': 'بدون هزینه',
    'discount': 'تخفیف',
    'discounts': 'تخفیف‌ها',
    'stock_quantity': 'موجودی محصول',
    'min_stock': 'اعلام حداقل موجودی محصول',
    'allow_comments': 'اجازه نظر دادن به محصول',
    'allow_rates': 'اجازه امتیاز دادن به محصول',
    'amount': 'مقدار',
    'length': 'طول (عدد ، متر)',
    'length_product': 'طول',
    'value': 'مقدار',
    'grouping': 'گروه‌بندی',
    'attribute': 'ویژگی',
    'unit': 'واحد',
    'categories': 'دسته‌بندی‌ها',
    'tags': 'برچسب‌ها',
    'parent': 'والد',
    'avatar': 'آواتار',
    'thumbnail': 'تصویر بندانگشتی',
    'album': 'آلبوم',
    'position': 'جایگاه',
    'province': 'استان',
    'city': 'شهر',
    'address': 'آدرس',
    'insert_address': 'آدرس را وارد کنید',
    'postal_code': 'کدپستی',
    'phone_number': 'شماره تلفن',
    'recipient_full_name': 'نام و نام خانوادگی تحویل گیرنده',
    'product_link': 'لینک محصول',
    'product': 'محصول',
    'product_s': 'محصول',
    'products': 'محصولات',
    'expiration_date': 'تاریخ انقضا',
    'expiration_time': 'زمان انقضا',
    'bank_name': 'نام بانک',
    'branch_name': 'نام شعبه',
    'account_number': 'شماره حساب',
    'card_number': 'شماره کارت',
    'shaba_number': 'شماره شبا',
    'account_owner_fullname': 'نام و نام خانوادگی صاحب حساب',
    'account': 'حساب',
    'money_amount': 'مبلغ',
    'cost': 'هزینه',
    'orderable_days': 'روزهای قابل سفارش',
    'start_hour': 'ساعت شروع',
    'end_hour': 'ساعت پایان',
    'order_number': 'شماره سفارش',
    'reply_message': 'متن پاسخ',
    'reference_number': 'شماره پیگیری',
    'ordered_amount': 'مقدار سفارش داده شده',
    'collected_amount': 'مقدار جمع‌آوری شده',
    'date_from': 'از تاریخ',
    'date_to': 'تا تاریخ',
    'payment_status': 'وضعیت پرداخت',
    'delivery_method': 'نحوه تحویل',
    'delivery_date': 'تاریخ تحویل',
    'delivery_time': 'زمان تحویل',
    'payment_method': 'نحوه پرداخت',
    'shipment_info': 'اطلاعات محموله ارسالی',
    'require_address': 'نیاز به آدرس دارد',
    'comment_text': 'متن نظر',
    'message': 'پیام',
    'send_as_anonymous': 'ارسال به صورت ناشناس',
    'refresh': 'به‌روزرسانی',
    'delete': 'حذف',
    'save_into_next_shopping_list': 'ذخیره در لیست خرید بعدی',
    'colleague': 'همکار',
    'option': 'آپشن',
    'order_type': 'نوع سفارش',
    'online': 'اینترنتی',
    'in_person': 'حضوری',
    'by_phone': 'تلفنی',
    'customer_mobile': 'شماره همراه مشتری',
    'timestamp': 'تاریخ و ساعت',
    'search_customer': 'جستجوی مشتری',
    'file_format': 'فرمت فایل',
    'image': 'عکس',
    'video': 'ویدیو',
    'user_type': 'نوع کاربر',
    'vendor': 'فروشنده',
    'customer': 'مشتری',
    'customer_vendor': 'مشتری/فروشنده',
    'type': 'نوع',
    'invoice': 'فاکتور',
    'invoice_number': 'شماره فاکتور',
    'person': 'شخص',
    'orderable_when_unavailable': 'قابلیت سفارش گذاری با موجودی کم‌تر از حد موردنیاز',
    'show_product_only_for_colleagues': 'نمایش محصول فقط برای همکاران',
    'order_submit_time': 'زمان ثبت سفارش',
    'customer_full_name': 'نام و نام خانوادگی مشتری',
    'payment_time': 'زمان پرداخت',
    'production_number': 'شماره تولید',
    'input_products_price': 'قیمت کالاهای ورودی',
    'output_products_price': 'قیمت کالاهای خروجی',
    'balance': 'بالانس',
    'discount_percentage': 'درصد تخفیف',
    'max_discountable_price': 'حداکثر مبلغ قابل تخفیف',
    'max_usable_number': 'حداکثر تعداد قابل استفاده',
    'active': 'فعال',
    'deactive': 'غیرفعال',
    'discountable': 'کد تخفیف بتواند بر روی محصول اعمال شود',
    'discount_code': 'کد تخفیف',
    'discount_price': 'مبلغ تخفیف',
    'receive_time': 'تاریخ دریافت',
    'real_or_legal_person_title': 'عنوان شخص حقیقی / حقوقی',
    'economic_number': 'شماره اقتصادی',
    'registration_or_national_number': 'شماره ثبت / ملی',
    'phone_or_fax_number': 'شماره تلفن / نمابر',
    'official_invoice_number': 'شماره فاکتور رسمی',
    'reference_code': 'کد پیگیری',
    'invoice_reference_code': 'کد پیگیری فاکتور',
    'activation': 'وضعیت فعال بودن',
    'code': 'کد',
    'pattern': 'طرح',
    'density': 'تراکم',
    'insert_density': 'تراکم را وارد کنید',
    'color': 'رنگ',
    'pecuniary': 'نقدی',
    'card_reader': 'کارتخوان',
    'check': 'چک',
    'entry_time': 'تاریخ ثبت ',
    'due_date_time': 'تاریخ سررسید',
    'check_amount': 'مبلغ چک',
    'issuing_bank': 'بانک صادر کننده',
    'raw':'خام _ تکمیل نشده',
    'completion':'خام _ تکمیل شده',
    'sewn':'دوخته _ تکمیل نشده',
    'completion_sewn':'دوخته _ تکمیل شده',
    'description_for_product':'توضیحات بیشتر درباره محصول',
    'custom_amount': 'مقدار سفارشی',
    'cloth_type': 'نوع پارچه',
    'material': 'جنس',
    'arrangment_color': 'رنگ زمینه',
    'arrangment': 'ترتیب ',
    'size': 'سایز',
    'place': 'مکان قرارگیری *',
    'pro_code':'کد انبار *',
    'tar':'تار',
    'pood':'پود',
    'customer_name': 'نام مشتری',
    'customer_code': 'کد مشتری',
    'receiver_name': 'نام گیرنده',
    'receiver_code': 'کد گیرنده',
    'process_type': 'وضعیت پارچه',
    'process_type_customer': 'وضعیت مورد نظر مشتری',
    'workshop_name': 'نام کارگاه',
    'reserve': 'رزرو',
    'production': 'تولید',
    'machine_name': 'نام دستگاه',
    'width': 'عرض',
    'product_code': 'کد محصول',
    'need_to_produce': 'درخواست تولید',
    'produced': 'کنترل کیفیت 1',
    'need_to_complete': 'نیاز به تکمیل',
    'print_complete': 'حواله تکمیل',
    'print_sewing': 'حواله خیاطی',
    'producing': 'درحال تولید',
    'completing': 'در حال تکمیل',
    'need_to_sewing': 'نیاز به خیاطی',
    'sewing': 'درحال خیاطی',
    'reservee': 'آماده رزرو',
    'reserved': 'رزرو شده',
    'show_ready_to_delivers': 'آماده تحویل',
    'delivered': 'تحویل داده شده',
    'report': 'بلاتکلیف',
    'remaining_length':'طول باقیمانده',
    'pick':'پیک',
    'gear':'دنده',
    'estimate_time':'تخمین زمان',
    'received_length':'طول دریافتی(عدد ، متر)',
    'need_to_sewing_lable': 'محصول نیاز به خیاطی دارد',
    'date': 'تاریخ',
    'sack_code': 'کد گونی',
    'role_code': 'کد رول',
    'last_role_code': ' آخرین کد رول :',
    'last_sack_code': ' آخرین کد گونی :',
    'last_warehouse_code': ' آخرین کد انبار :',
    'insert_meterial': ' جنس مورد نظر را وارد کنید',
    'insert_Warehouse_code': ' کد انبار مورد نظر را وارد کنید',
    'select_Warehouse': '  انبار مورد نظر را انتخاب کنید',
    'insert_pattern': ' طرح مورد نظر را وارد کنید',
    'insert_customer_name': ' نام مشتری مورد نظر را وارد کنید',
    'insert_order_code': ' شماره سفارش مورد نظر را وارد کنید',
    'insert_customer_name_or_order_code': '  شماره سفارش یا نام مشتری',
    'insert_cloth': ' نوع پارچه مورد نظر را وارد کنید',
    'insert_size': ' سایز  مورد نظر را وارد کنید',
    'in_progress_orders': 'سفارشات در حال پردازش',
    'machine':'دستگاه',
    'workshop':'کارگاه',
    'warehouse':'انبار',
    'order_progress_details':'جزئیات پیشرفت سفارش',
    'get_print':'تاریخ دریافت رسید',
    'number_print':'شماره',
    'receiver':'تحویل گیرنده',
    'services': 'خدمات درخواستی',
    'complete':'تکمیل',
    'order_list':'لیست سفارشات',
    'file':'فایل',
    'scrap':' ضایعات:',
    'scraps':'تعداد  <br> ضایعات:',
    'shift':'شیفت:',
    'operator':'اپراتور:',
    'packed_number':'تعداد بسته بندی شده:',
    'loaded_packed_number':'تعداد کارتن بارگیری شده:',
    'packing_plastic_serial':'شماره تایید شده کاغذ بسته بندی:',
    'packing_paper_serial':'شماره تایید شده پلاستیک بسته بندی:',
    'report_packing':'گزارش کار:',
    'packing_staffs':'نیرو های بسته بندی:',
    'AssemblyTrackingProcessForm':'فرایند ردیابی مونتاژ',
    'rework':'دوباره کاری',
    'number_assembled':'تعداد منتاژ شده ها:',
    'number_basket':'تعداد سبد:',
    'number_entrance_sil_oil':'شماره مواد ورودی روغن سیلیکون:',
    'input_plunger_serial':'شماره مواد <br> ورودی پلانجر',
    'input_printed_brl_serial':'شماره مواد <br>  ورودی بارل چاپ خورده',
    'input_gasket_serial':'شماره مواد <br>  ورودی گسکت',
    'input_needle_serial':'شماره مواد <br> ورودی نیدل',
    'output_serial':'شماره  <br> محصول خروجی',
    'Part_type':'نوع قطعه',
    'pack_number':'تعداد بسته:',
    'printed_number':'تعداد چاپ شده:',
    'date_shift':'تاریخ/شیفت:',
    'Number_approved_halal_input_materials':'شماره مواد ورودی حلال تایید شده:',
    'Number_approved_ink_input_materials':'شماره مواد ورودی جوهر تایید شده:',
    'ControlTrackingBarrelPrintingProcessForm':'فرم ردیابی فرآیند چاپ بارل',
    'Input_material_number':'شماره مواد ورودی:',
    'Input_material_approve':'تایید مواد ورودی:',
    'Production_piece':'قطعه تولیدی',
    'total_number':'تعداد کل',
    'Identification_tracking':'شناسایی و ردیابی',
    'start_date':'تاریخ شروع',
    'end_date':'تاریخ پایان',

  },
  'placeholders': {
    'placeholder_signature':'امضای خود را انتخاب کنید',
    'name':'نام را وارد کنید',
    'last_name':'نام خانوادگی را وارد کنید',
    'select_customer':'مشتری را انتخاب کنید',
    'lotNumber':'شماره لات را انتخاب کنید',
    'mobile': 'شماره همراه',
    'insert_name':'نام را وارد کنید', 
    'enter_mobile': 'شماره همراه خود را وارد کنید',
    'select_parent_category': 'دسته والد را انتخاب کنید',
    'select_product_unit': 'سایز محصول را انتخاب کنید',
    'select_unit': 'واحد محصول را انتخاب کنید',
    'select_product_category': 'دسته یا دسته‌های محصول را انتخاب کنید',
    'select_product_tags': 'برچسب‌های محصول را انتخاب کنید',
    'select_product_image_position': 'جایگاه عکس محصول را انتخاب کنید',
    'select_product_attribute': 'ویژگی محصول را انتخاب کنید',
    'select_unit_grouping': 'گروه‌بندی واحد را انتخاب کنید',
    'select_attribute_grouping': 'گروه‌بندی ویژگی را انتخاب کنید',
    'select_user_role': 'نقش کاربر را انتخاب کنید',
    'select_user_type': 'نوع کاربر را انتخاب کنید',
    'select_product_type':'نوع محصول را انتخاب نمایید',
    'select_user_gender': 'جنسیت کاربر را انتخاب کنید',
    'select_supplier':'تامین کننده را انتخاب کنید',
    'select_raw_material':'مواد اولیه را انتخاب کنید',
    'select_logo':'لوگو  را انتخاب کنید',
    'select_gender': 'جنسیت را انتخاب کنید',
    'select_province': 'استان را انتخاب کنید',
    'select_production': 'محصول در جریان ساخت را انتخاب کنید',
    'select_product': 'محصول را انتخاب کنید',
    'select_products': 'محصولات را انتخاب کنید',
    'select_city': 'شهر را انتخاب کنید',
    'select_warehouse_type': 'نوع انبار را انتخاب کنید',
    'select_workshop_type': 'نوع کارگاه را انتخاب کنید',
    'select_workshop': ' کارگاه را انتخاب کنید',
    'select_cloth_type': 'نوع پارچه را انتخاب کنید',
    'select_process_type': 'نوع فعالیت را انتخاب کنید',
    'select_pattern_color': 'رنگ زمینه را انتخاب کنید',
    'select_pattern': 'نام طرح را انتخاب کنید',
    'select_material': 'جنس پارچه را انتخاب کنید',
    'select_machine': 'نوع دستگاه را انتخاب کنید',
    'select_packing_type': 'نوع بسته بندی را انتخاب کنید',
    'select_pack': 'بسته را انتخاب کنید',
    'select_final_product': ' محصول نهایی را انتخاب کنید',
    'select_product_default': ' محصول را انتخاب کنید',
    'select_depo_product': ' محصول در جریان ساخت را انتخاب کنید',
    'select_raw_material_product': 'محصول در جریان ساخت را انتخاب کنید',
    'select_machines': ' دستگاه را انتخاب کنید',
    'select_tar': 'نوع تار را انتخاب کنید',
    'select_pood': 'نوع پود را انتخاب کنید',
    'select_bank_account_to_deposit': 'حساب موردنظر برای واریز را انتخاب کنید',
    'select_payment_method': 'روش پرداخت را انتخاب کنید',
    'select_payment_status': 'وضعیت پرداخت را انتخاب کنید',
    'select_delivery_method': 'نحوه تحویل را انتخاب کنید',
    'select_delivery_date': 'تاریخ تحویل را انتخاب کنید',
    'select_delivery_time': 'زمان تحویل را انتخاب کنید',
    'select_desired_option': 'گزینه موردنظر را انتخاب کنید',
    'select_user_colleague_type': 'نوع همکاری کاربر را انتخاب کنید',
    'select_person': 'شخص را انتخاب کنید',
    'search_person': 'جستجوی شخص',
    'search_warehouse': 'جستجو در انبار',
    'search_orders': 'جستجو در سفارشات',
    'search_customer': 'جستجوی مشتری',
    'search_receiver': 'جستجوی گیرنده',
    'search_vendor': 'جستجوی فروشنده',
    'click_to_select_file': 'برای انتخاب فایل کلیک کنید',
    'search_product': 'نام محصول موردنظر خود را جستجو کنید',
    'start_typing_to_search': 'برای جستجو شروع به تایپ کنید',
    'no_result_found': 'نتیجه‌ای یافت نشد',
    'stocks': 'موجودی ها',
    'select_Warehouse': '  انبار مورد نظر را انتخاب کنید',
    'select_date': 'تاریخ را انتخاب کنید',
    'select_type': 'نوع را انتخاب کنید',
    'select_uid': 'uidها را انتخاب کنید',

  },
  'attributes': {
    'active': 'فعال',
    'banned': 'مسدود شده',
    'published': 'منتشر شده',
    'unpublished': 'منتشر نشده',
    'special_sale': 'فروش ویژه',
    'stock_available': 'موجود',
    'stock_running_out': '{count} {unit} باقی مانده',
    'running_out': 'در حال اتمام',
    'stock_not_available': 'ناموجود',
    'ad': 'تبلیغات',
    'pending': 'در حال انتظار',
    'confirmed': 'تایید شده',
    'rejected': 'رد شده',
    'paid': 'پرداخت شده',
    'not_paid': 'پرداخت نشده',
    'nameless': 'بدون نام',
    'successful': 'موفق',
    'unsuccessful': 'ناموفق',
    'cancelled': 'لغو شده',


  },
  'tables': {
    'total_value_finished':'جمع مقدار تمام شده',
    'total_value_not_finished':'جمع مقدارتمام نشده',
    'height_tolerance':' تلرانس ارتفاع',
    'diameter_tolerance':' تلرانس قطر',
    'test_type':'نوع آزمایش',
    'thumb_case':'جا انگشتی',
    'thumb_case_tolerance':'تلرانس جا انگشتی',
    'start_time':'زمان شروع',
    'clear_filters':'حدف فیلتر',
    'end_time':'زمان پایان',
    'Meterage' :'متراژ',
    'nominal_capacity':'ظرفیت اسمی',
    'user_name':'نام کاربری',
    'warehouse_name':'نام انبار',
    'warehouse_type':'نوع انبار',
    'expired_date':'تاریخ انقضا',
    'serial':'سریال',
    'lot_number':'شماره لات',
    'uid':'UID',
    'packing_type':'نوع بسته بندی',
    'pack':'بسته',
    'logo':'لوگو',
    'piece_name':'نام قطعه',
    'diameter':'قطر',
    'height':'ارتفاع',
    'company_name':'نام شرکت',
    'abbreviation':'مخفف',
    'raw_materials':'مواد اولیه',
    'raw_materials_name':'نام مواد اولیه',
    'raw_materials_code':'کد مواد اولیه',
    'mfg_date':'تاریخ تولید',
    'exp_date':'تاریخ انقضا',
    'supplier':'تامین کننده',
    'supplier_name':'شرکت تولید کننده',
    'total_weight':'وزن کل',
    'user_type':'نوع کاربر',
    'final_product_packing':'محصول نهایی ',
    'depo_product':'محصول در جریان ساخت ',
    'raw_material_product':'محصول در جریان ساخت ',
    'raw_material':'مواد اولیه ',
    'index': 'ردیف',
    'color': 'رنگ',
    'density': 'تراکم',
    'map_code':'کد نقشه',
    'pattern': 'طرح',
    'title': 'عنوان',
    'type': 'نوع پارچه',
    'number_of_colors':'تعداد رنگ',
    'ordering_color': 'رنگ زمینه',
    'material':'جنس',
    'length':'طول',
    'stock_product':'موجودی محصول',
    'users_count': 'تعداد کاربران',
    'mobile': 'شماره همراه',
    'full_name': 'نام و نام خانوادگی',
    'role': 'نقش',
    'unit_name': 'ابعاد',
    'description_unit': 'قطعات',
    'zarib': 'ضریب',
    'size': 'سایز',
    'size_meter': 'موجودی(متر)',
    'colleague': 'همکار',
    'status': 'وضعیت',
    'registration_date': 'تاریخ ثبت نام',
    'actions': 'اقدامات',
    'image': 'عکس',
    'unit': 'واحد',
    'fee': 'فی',
    'price': 'قیمت',
    'onsale_price': 'قیمت با تخفیف',
    'unit_price': 'قیمت واحد',
    'unit_onsale_price': 'قیمت واحد با تخفیف',
    'total_price': 'قیمت کل',
    'categories': 'دسته‌بندی‌ها',
    'tags': 'برچسب‌ها',
    'stock_quantity': 'موجودی انبار',
    'total_sales': 'فروش کل',
    'value': 'مقدار',
    'province': 'استان',
    'city': 'شهر',
    'address': 'آدرس',
    'postal_code': 'کدپستی',
    'phone_number': 'شماره تلفن',
    'recipient': 'تحویل گیرنده',
    'user_mobile': 'شماره همراه کاربر',
    'user_full_name': 'نام و نام خانوادگی کاربر',
    'customer_mobile': 'شماره همراه مشتری',
    'customer_full_name': 'مشتری',
    'receiver_full_name': 'گیرنده',
    'reference_number': 'شماره پیگیری',
    'datetime': 'تاریخ و ساعت',
    'order_submit_time': 'زمان ثبت سفارش',
    'payment_time': 'زمان پرداخت',
    'money_amount': 'مبلغ',
    'discount': 'تخفیف',
    'total_money_amount': 'مبلغ کل',
    'products_price_without_discount': 'قیمت کالاها بدون تخفیف',
    'delivery_cost': 'هزینه ارسال',
    'discounts': 'تخفیف‌ها',
    'total_summation': 'جمع کل',
    'account': 'حساب',
    'reply_message': 'متن پاسخ',
    'bank_name': 'نام بانک',
    'branch_name': 'نام شعبه',
    'account_number': 'شماره حساب',
    'card_number': 'شماره کارت',
    'shaba_number': 'شماره شبا',
    'account_owner_fullname': 'نام و نام خانوادگی صاحب حساب',
    'cost': 'هزینه',
    'start_hour': 'ساعت شروع',
    'end_hour': 'ساعت پایان',
    'order_number': 'شماره سفارش',
    'sack_code': 'کد گونی',
    'payment_method': 'نحوه پرداخت',
    'payment_status': 'وضعیت پرداخت',
    'delivery_method': 'نحوه تحویل',
    'delivery_time': 'زمان تحویل',
    'amount': 'مقدار',
    'product': 'محصول',
    'shipment_info': 'اطلاعات محموله ارسالی',
    'require_address': 'نیاز به آدرس دارد',
    'user': 'کاربر',
    'text': 'متن',
    'rate': 'رتبه',
    'anonymously': 'به صورت ناشناس',
    'buy_advice': 'توصیه خرید',
    'comment_author': 'نویسنده نظر',
    'reports_number': 'تعداد گزارشات',
    'order_status': 'وضعیت سفارش',
    'request_method': 'متد درخواست',
    'request_url': 'url درخواست',
    'request_body_params': 'پارامترهای ورودی درخواست',
    'display_order': 'ترتیب نمایش',
    'file_format': 'فرمت فایل',
    'file': 'فایل',
    'expiration_date': 'تاریخ انقضا',
    'invoice_type': 'نوع فاکتور',
    'invoice_number': 'شماره فاکتور',
    'person': 'شخص',
    'description': 'توضیحات',
    'first_name': 'نام',
    'last_name': 'نام خانوادگی',
    'product_title': 'عنوان محصول',
    'option': 'آپشن',
    'balance': 'بالانس',
    'production_number': 'شماره تولید',
    'input_products_price': 'قیمت کالاهای ورودی',
    'output_products_price': 'قیمت کالاهای خروجی',
    'reason': 'بابت',
    'code': 'کد',
    'product_code': 'کد محصول',
    'order_code': 'کد سفارش',
    'customer_code': 'کد مشتری',
    'detail':'توضیحات',
    'percentage': 'درصد',
    'max_discountable_price': 'حداکثر مبلغ قابل تخفیف',
    'max_usable_number': 'حداکثر تعداد قابل استفاده',
    'used_number': 'تعداد استفاده شده',
    'customer': 'مشتری',
    'active': 'فعال',
    'start_date': 'تاریخ شروع',
    'number': 'شماره',
    'invoice_reference_code': 'کد پیگیری فاکتور',
    'stock': 'موجودی',
    'warehouse_code':'کد انبار',
    'place': 'مکان',
    'arrangement': 'ترتیب',
    'workshop_name': 'نام کارگاه',
    'workshop_type': 'نوع کارگاه',
    'machine_name': 'نام دستگاه',
    'machine_type': 'نوع دستگاه',
    'width': 'عرض',
    'quantity': 'تعداد',
    'process': 'وضعیت ',
    'tar': 'تار ',
    'pood': 'پود ',
    'gear': 'دنده ',
    'pick': 'پیک ',
    'machine': 'دستگاه ',
    'receive_time': 'زمان دریافت ',
    'estimate_time': 'زمان تخمین ',
    'customer_name': 'نام مشتری',
    'role_code': 'کد رول',
    'color_code': 'کد رنگ',
    'length_number': 'طول / تعداد',
    'date': 'تاریخ',
    'search': 'جستجو',
    'submit_order_date': 'تاریخ ثبت سفارش',



  },
  'pages': {
    'home': {
      'title': 'صفحه اصلی',
      'support_24': 'پشتیبانی 24 ساعته',
      'fast_delivery': 'ارسال سریع سفارش',
      'on_delivery_payment': 'پرداخت در محل',
      'special_offer': 'پیشنهاد ویژه',
      'protein_products': 'محصولات پروتئینی',
      'bestselling_products': 'پرفروش‌ترین محصولات',
    },
    'login': {
      'title': 'ورود به سایت',
      'enter_login_credentials': 'لطفا اطلاعات خود را برای ورود به سایت وارد کنید',
      'forget_password': 'فراموشی رمز عبور'
    },
    'register': {
      'title': 'ثبت نام در سایت',
      'enter_mobile': 'شماره همراه خود را وارد کنید',
      'enter_code': 'کد تایید ارسال شده به شماره همراه خود را وارد کنید',
      'enter_password': 'برای حساب کاربری خود، رمز عبور انتخاب کنید',
      'accept_conditions_first_part': 'با ثبت نام در سایت، شما',
      'accept_conditions_link': 'شرایط و قوانین',
      'accept_conditions_last_part': ' را پذیرفته‌اید',
      'registered_before': 'قبلا در سایت ثبت نام کرده‌ام'
    },
    'forgot_password': {
      'title': 'بازیابی رمز عبور',
      'enter_mobile': 'شماره همراه خود را وارد کنید',
      'enter_code': 'کد تایید ارسال شده به شماره همراه خود را وارد کنید',
      'enter_password': 'رمز عبور جدید را وارد کنید',
      'log_into_site': 'ورود به سایت'
    },
    'admin_panel_dashboard': {
      'title': 'میز کار'
    },
    'roles': {
      'roles_list': 'لیست نقش‌های سایت',
      'roles_list_description': 'در این بخش می‌توانید نقش‌های سایت را ویرایش یا حذف کنید.',
      'add_role': 'ایجاد نقش جدید',
      'add_role_description': 'در این قسمت می‌توانید نقش جدیدی برای کاربران تعریف کنید و دسترسی‌های آن را تعیین نمایید.',
      'edit_role': 'ویرایش نقش',
      'edit_role_description': 'در این صفحه می‌توانید عنوان، توضیحات یا دسترسی‌های نقش را ویرایش کنید.',
      'role_description': 'توضیحات نقش',
      'role_permissions': 'دسترسی‌های نقش'
    },
    'user_types': {
      'user_types_list': 'لیست نقش‌های سایت',
      'user_types_list_description': 'در این بخش می‌توانید نوع کاربران را ویرایش یا حذف کنید.',
      'add_user_types': 'ایجاد نوع کاربر جدید',
      'add_role_description': 'در این قسمت می‌توانید نقش جدیدی برای کاربران تعریف کنید و دسترسی‌های آن را تعیین نمایید.',
      'edit_role': 'ویرایش نقش',
      'edit_role_description': 'در این صفحه می‌توانید عنوان، توضیحات یا دسترسی‌های نقش را ویرایش کنید.',
      'role_description': 'توضیحات نقش',
      'role_permissions': 'دسترسی‌های نقش'
    },
    'colleagues': {
      'colleagues_list': 'لیست انواع همکار',
      'colleagues_list_description': 'در این بخش می‌توانید لیست انواع همکار تعریف شده را مشاهده کنید و در صورت لزوم آن‌ها را ویرایش یا حذف نمایید.',
      'add_colleague': 'ایجاد همکار',
      'add_colleague_description': 'در این بخش می‌توانید نوع جدیدی از همکار را تعریف کنید'
    },
    'users': {
      'users_list': 'لیست کاربران سایت',
      'users_list_description': 'در این بخش می‌توانید لیست کاربران سایت را به همراه پروفایل و مشخصات آن‌ها مشاهده کنید و در صورت لزوم اطلاعات کاربر را ویرایش کنید. همچنین می‌توانید کاربران را مسدود یا حذف نمایید.',
      'add_user': 'ایجاد کاربر جدید',
      'add_user_description': 'در این قسمت می‌توانید کاربر جدیدی را اضافه نمایید و کاربران را مدیریت کنید.',
      'edit_user': 'ویرایش کاربر',
      'edit_user_description': 'در این صفحه می‌توانید اطلاعات کاربر را ویرایش کنید.',
      'account_information': 'اطلاعات حساب کاربری',
      'color_information': 'اطلاعات رنگ',
      'personal_information': 'اطلاعات شخصی'
    },
    'profile': {
      'account_information': 'اطلاعات حساب کاربری',
      'my_orders': 'سفارش‌های من',
      'wallet': 'کیف پول',
      'sign_out': 'خروج',
      'personal_information': 'اطلاعات شخصی',
      'edit_personal_information': 'ویرایش اطلاعات شخصی',
      'edit_personal_information_description': 'در این بخش می‌توانید اطلاعات شخصی خود را ویرایش کنید.',
      'my_addresses': 'آدرس‌های من',
      'my_addresses_list': 'لیست آدرس‌های من',
      'my_addresses_list_description': 'در این بخش می‌توانید آدرس‌های خود را مدیریت کنید.',
      'add_address': 'ایجاد آدرس جدید',
      'default_address': 'آدرس پیش فرض',
      'add_address_description': 'در این قسمت می‌توانید آدرس جدیدی برای حساب کاربری خود ایجاد کنید.',
      'edit_address': 'ویرایش آدرس',
      'edit_address_description': 'در این قسمت می‌توانید آدرس موردنظر را ویرایش کنید.',
      'password': 'رمز عبور',
      'change_password': 'تغییر رمز عبور حساب کاربری',
      'change_password_description': 'در این بخش می‌توانید رمز ورود به حساب کاربری خود را تغییر دهید.'
    },
    'units': {
      'units_list': 'لیست واحدها',
      'units_list_description': 'در این بخش می‌توانید واحدهای محصولات را مشاهده کنید و در صورت لزوم آن‌ها را ویرایش یا حذف کنید.',
      'add_unit': 'ایجاد واحد برای محصول',
      'add_unit_description': 'در این بخش می‌توانید واحد جدیدی برای محصولات سایت اضافه کنید.',
      'add_unit_grouping_description': 'در صورتی که گروه‌بندی برای واحد انتخاب نکنید، به عنوان گروه جدید اضافه خواهد شد.',
      'edit_unit': 'ویرایش واحد',
      'edit_unit_description': 'در این صفحه می‌توانید اطلاعات مربوط به واحد را ویرایش نمایید'
    },
    'workshop': {
      'add_workshop': 'ایجاد کارگاه',
      'add_workshop_description': 'در این بخش می‌توانید کارگاه جدیدی ایجاد کنید.',
      'edit_workshop': 'ویرایش کارگاه',
      'edit_workshop_description': 'در این صفحه می‌توانید اطلاعات مربوط به کارگاه را ویرایش نمایید'
    },
    'machine': {
      'add_machine': 'ایجاد دستگاه',
      'add_machine_type': 'مدیریت نوع دستگاه',
      'add_machine_description': 'در این بخش می‌توانید دستگاه جدیدی ایجاد کنید.',
      'add_machine_type_description': 'در این بخش می‌توانید نوع دستگاه ها را تعریف و مدیریت کنید.',
      'edit_machine': 'ویرایش دستگاه',
      'edit_machine_description': 'در این صفحه می‌توانید اطلاعات مربوط به دستگاه را ویرایش نمایید',
      'show_machine_list': 'لیست خروجی دستگاه ها',
      'show_producing_list': 'لیست در حال تولید',
      'show_machine_list_description': 'در این صفحه می‌توانید لیست تولیدی دستگاه ها را مشاهده و خروجی بگیرید',
      'machine_name': 'دستگاه :',
      'machine_description': 'دستگاه :'

    },
    'categories': {
      'categories_list': 'لیست دسته‌بندی‌ها',
      'categories_list_description': 'در این بخش می‌توانید دسته‌بندی‌های محصولات را مشاهده کنید و در صورت لزوم آن‌ها را ویرایش یا حذف کنید.',
      'add_category': 'ایجاد دسته‌بندی محصول',
      'add_category_description': 'در این بخش می‌توانید دسته‌بندی جدیدی برای محصولات سایت اضافه کنید. دسته‌بندی جدید می‌تواند یک دسته‌بندی جدا و یا زیردسته‌ای از دسته‌بندی دیگر باشد.',
      'edit_category': 'ویرایش دسته‌بندی',
      'edit_category_description': 'در این صفحه می‌توانید اطلاعات مربوط به دسته‌بندی را ویرایش نمایید',
      'category_ads': 'تبلیغات دسته',
      'products_list_first_ad': 'محصول تبلیغاتی رتبه اول لیست محصولات گروه'
    },
    'tags': {
      'tags_list': 'لیست برچسب‌ها',
      'tags_list_description': 'در این بخش می‌توانید لیست برچسب‌های تعریف شده برای محصولات را مشاهده کنید و در صورت لزوم آن‌ها را ویرایش یا حذف کنید.',
      'add_tag': 'ایجاد برچسب برای محصول',
      'add_tag_description': 'در این بخش می‌توانید برچسب جدیدی برای محصولات سایت اضافه کنید'
    },
    'attributes': {
      'attributes_list': 'لیست مشخصات',
      'attributes_list_description': 'در این بخش می‌توانید مشخصات محصولات را مشاهده کنید و در صورت لزوم آن‌ها را ویرایش یا حذف کنید.',
      'add_attribute': 'ایجاد مشخصات برای محصول',
      'add_attribute_description': 'در این بخش می‌توانید ویژگی جدیدی برای محصولات سایت اضافه کنید.',
      'add_attribute_grouping_description': 'در صورتی که گروه‌بندی برای ویژگی انتخاب نکنید، به عنوان گروه جدید اضافه خواهد شد.',
      'edit_attribute': 'ویرایش ویژگی',
      'edit_attribute_description': 'در این صفحه می‌توانید اطلاعات مربوط به ویژگی را ویرایش نمایید',
      'intended_attribute_not_exists': 'ویژگی موردنظر شما وجود ندارد؟',
      'add_new_attribute': 'ایجاد ویژگی جدید'
    },
    'times': {
      'times_list': ' شیفت های کاری',
      'times_description': 'مدیریت شیفت',
    },
    'products': {
      'products_list': 'لیست دپو',
      'final_product_list': 'لیست محصولات نهایی',
      'products_list_description': 'در این بخش می‌توانید لیست دپو را مشاهده و مدیریت نمایید.',
      'final_product_list_description': 'در این بخش می‌توانید لیست محصولات نهایی را مشاهده و مدیریت نمایید.',
      'add_product': 'ایجاد محصول جدید',
      'add_product_to_warehouse': 'اضافه کردن محصول جدید به انبار',
      'add_pattern': 'ایجاد طرح پارچه جدید',
      'add_material': 'ایجاد جنس پارچه جدید',
      'add_unit': 'ایجاد سایز جدید',
      'add_pood_material': 'ایجاد جنس پود جدید',
      'edit_pattern': 'ویرایش طرح پارچه ',
      'edit_pood_material': 'ویرایش جنس پود ',
      'edit_material': 'ویرایش جنس پارچه ',
      'edit_unit': 'ویرایش سایز ترمه ',
      'add_product_description': 'در این صفحه می‌توانید محصول جدیدی را ایجاد کنید.',
      'add_pattern_product_description': 'در این صفحه می‌توانید طرح جدیدی برای پارچه ها ایجاد کنید.',
      'add_material_product_description': 'در این صفحه می‌توانید جنس جدیدی برای پارچه ها ایجاد کنید.',
      'edit_unit_product_description': 'در این صفحه می‌توانید سایز  ترمه را ویرایش کنید.',
      'add_pood_material_description': 'در این صفحه می‌توانید جنس پود جدید ایجاد و مدیریت نمایید.',
      'edit_pood_material_description': 'در این صفحه می‌توانید جنس پود را ویرایش کنید.',
      'edit_pattern_product_description': 'در این صفحه می‌توانید طرح پارچه را ویرایش کنید',
      'edit_material_product_description': 'در این صفحه می‌توانید جنس پارچه را ویرایش کنید.',
      'add_unit_product_description': 'در این صفحه می‌توانید  سایز جدیدی برای ترمه و دابی ایجاد کنید و آنها را مدیریت نمایید.',
      'edit_product': 'ویرایش محصول',
      'edit_product_description': 'در این صفحه می‌توانید مشخصات و اطلاعات مربوط به محصول را ویرایش نمایید.',
      'product_information': 'اطلاعات محصول',
      'product_categories': 'دسته‌بندی‌های محصول',
      'product_tags': 'برچسب‌های محصول',
      'product_image': 'عکس محصول',
      'product_images': 'عکس‌های محصول',
      'product_attributes': 'مشخصات محصول',
      'product_options': 'آپشن‌های محصول',
      'product_title_and_description': 'اطلاعات محصول',
      'warehouse_title_and_description': 'اطلاعات انبار',
      'product_price': 'قیمت محصول',
      'colleague_product_prices': 'قیمت محصول برای همکاران',
      'product_stock': 'موجودی محصول',
      'product_comments': 'نظرات محصول',
      'product_link': 'لینک محصول',
      'ordering': 'سفارش‌گذاری',
      'action': 'اقدام',

    },
    'defaults': {
      'injection': 'پیش فرض های تزریق',
      'silicon':'پیش فرض های مونتاژ',
      'print':'پیش فرض های چاپ',
      'result_form':'پیش فرض های فرم نتایج آزمون',
    },
    'productions': {
      'producing': 'تولید ',
      'producing_formula': 'فرمول تولید',
      'producing_product_description': 'در این صفحه می‌توانید از محصولات موجود در انبار به عنوان مواد اولیه، یکی از محصولات فروشگاه را تولید کنید',
      'production_information': 'اطلاعات تولید',
      'output_products': 'محصولات خروجی',
      'input_products': 'محصولات ورودی',
      'productions_report': 'گزارش تولید',
      'productions_report_description': 'در این صفحه می‌توانید گزارش تولیدهای گذشته را مشاهده کنید و در صورت نیاز آن‌ها را حذف یا ویرایش کنید',
      'edit_production': 'ویرایش تولید',
      'edit_production_description': 'در این صفحه می‌توانید اطلاعات و محصولات یک فرآیند تولید را ویرایش کنید',
      'production_details': 'جزئیات تولید'
    },
    'comments': {
      'comments_list_description': 'در این بخش می‌توانید نظرات ثبت شده کاربران در خصوص محصولات را مشاهده و در صورت لزوم آن‌ها را تایید یا رد کنید.',
      'reported_comments_list_description': 'در این بخش می‌توانید نظرات گزارش شده را مشاهده و در صورت لزوم آن‌ها را حذف کنید',
      'buy_advice': {
        'recommend': 'خرید این محصول را توصیه می‌کنم',
        'not_sure': 'در خرید این محصول تردید دارم'
      },
      'report': {
        'unsuitable_content': 'محتوای نامناسب',
        'ads': 'تبلیغات'
      }
    },
    'people': {
      'add_person': 'ایجاد شخص (فروشنده یا مشتری)',
      'add_customer': 'ایجاد مشتری',
      'add_person_description': 'در این بخش می‌توانید یک مشتری جدید تعریف کنید',
      'edit_person': {
        'vendor': 'ویرایش فروشنده',
        'customer': 'ویرایش مشتری'
      },
      'edit_person_description': 'در این صفحه می‌توانید اطلاعات مربوط به شخص را ویرایش کنید',
      'people_list': 'لیست اشخاص',
      'people_list_description': 'در این بخش می‌توانید لیست اشخاص (فروشندگان کالا به فروشگاه و مشتریان فروشگاه) را مشاهده کنید و در صورت لزوم آن‌ها را ویرایش یا حذف کنید',
      'people_financial_report': 'گردش مالی اشخاص',
      'people_financial_report_description': 'در این صفحه می‌توانید گزارشی از گردش مالی اشخاص موردنظر را مشاهده کنید',
      'person_financial_balance': 'بالانس مالی شخص',
      'information_for_official_invoice': 'اطلاعات برای صدور فاکتور رسمی',
      'people_description':'اطلاعات اشخاص'
    },
    'supplier': {
      'add_person': 'ایجاد تامین کننده',
      'add_person_description': 'در این بخش می‌توانید یک تامین کننده جدید تعریف کنید',
      'edit_person': {
        'vendor': 'ویرایش تامین کننده',
        'customer': 'ویرایش تامین کننده'            
      }
    },       
    'raw_material': {
      'title': 'مدیریت مواد اولیه',
      'entry_title': 'مدیریت ورودی مواد اولیه',
      'description': 'در این بخش می‌توانید مواد اولیه جدید تعریف کنید',
      'edit_person': {
        'vendor': 'ویرایش مواد اولیه',
        'customer': 'ویرایش تامین اولیه'            
      }
    },       
    'packing_type': {
      'header':'مدیریت نوع بسته بندی',
      'title': ' اولیه',
      'secondary_title': 'بسته بندی ثانویه',
      'packing_type_title': 'مدیریت نوع بسته بندی',
      'primary_title': 'بسته بندی اولیه',
      'description': 'در این بخش می‌توانید نوع بسته بندی جدید تعریف کنید',
      'edit_person': {
        'vendor': 'ویرایش مواد اولیه',
        'customer': 'ویرایش تامین اولیه'            
      }
    },       
    'config': {
      'title': 'تنظیمات سربرگ',
    },       
    'degazin': {
      'title': 'دگزین',
      'header':'دپو قبل و بعد از استریل',
      'description': 'در این بخش میتوانید دپو قبل و بعد از استریل را مدیریت کنید',
    },       
    'instructions': {
      'title': 'مدیریت دستورالعمل ها',
      'list': 'لیست دستورالعمل ها',
    },       

    'ecommerce': {
      'order_by_based_on': 'مرتب سازی بر اساس',
      'best_selling': 'پرفروش‌ترین',
      'most_popular': 'محبوب‌ترین',
      'newest': 'جدیدترین',
      'cheapest': 'ارزان‌ترین',
      'most_expensive': 'گران‌ترین',
      'search_filters': 'فیلترهای جستجو',
      'price_range': 'بازه قیمت',
      'only_stock_available_products': 'فقط کالاهای موجود در انبار',
      'similar_products': 'محصولات مشابه',
      'support_24': 'پشتیبانی 24 ساعته',
      'fast_delivery': 'ارسال سریع سفارش',
      'on_delivery_payment': 'پرداخت در محل',
      'about_product': 'درباره محصول',
      'product_attributes': 'مشخصات محصول',
      'users_comments': 'نظرات کاربران',
      'search_results': 'نتایج جستجو'
    },
    'warehouse': {
      'warehouse_list_page':'در این بخش می توانید لیست انبار ها را مشاهده و تغییرات را انجام دهید',
      'stock_warehouse_page':'در این بخش می توانید لیست انبار ها را مشاهده و موجودی هر کدام را تغییر دهید',
      'search_in_stock_warehouse':'در این بخش می توانید موجودی انبار ها را بر اساس کد انبار مشاهده و موجودی هر کدام را تغییر دهید',
      'warehouse_information': 'اطلاعات انبار',
      'warehouses': ' انبار',
      'stock_warehouses': 'موجودی انبار',

    },
    'cart': {
      'shopping_cart': 'سبد خرید',
      'next_shopping_list': 'لیست خرید بعدی',
      'add_to_cart': 'افزودن به سبد خرید',
      'add_all_to_cart': 'افزودن همه به سبد خرید',
      'unit_price': 'قیمت واحد',
      'unit_onsale_price': 'قیمت واحد با تخفیف',
      //'total_price': 'قیمت کل',
      'products_price': 'قیمت کالاها',
      'your_cart_is_empty': 'سبد خرید شما خالی است',
      'see_products': 'مشاهده محصولات',
      'login_to_account': 'ورود به حساب کاربری',
      'login_to_see_your_cart': 'برای مشاهده محصولاتی که پیش‌تر به سبد خرید خود اضافه کرده‌اید وارد حساب کاربری شوید',
      'your_next_shopping_list_is_empty': 'لیست خرید بعدی شما خالی است',
      'count': 'تعداد',
      'products_count': 'تعداد کالاها',
      'discount': 'تخفیف',
      'products_discount': 'تخفیف کالاها',
      'final_price': 'قیمت نهایی',
      'cart_total_price': 'جمع سبد خرید',
      'total_price': 'جمع کل',
      'delivery_cost_note': 'هزینه ارسال در ادامه بر اساس آدرس، زمان و نحوه ارسال انتخابی شما محاسبه و به این مبلغ اضافه خواهد شد.',
      'next_shopping_list_information_title': 'لیست خرید بعدی چیست؟',
      'next_shopping_list_information_text': 'در صورتی که تمایل به خرید محصولی دارید، ولی هم‌اکنون آمادگی خرید آن را ندارید، می‌توانید در لیست خرید بعدی خود ذخیره کنید تا در زمان مناسب، نسبت به خرید آن‌ها اقدام نمایید.',
      'next_shopping_list_counter': '{count} کالا در لیست خرید بعدی شماست.',
      'set_to_back': 'اعمال بر روی زمینه'
    },
    'wallet': {
      'credit': 'موجودی',
      'checkouts_list': 'درخواست‌های تسویه',
      'checkouts_list_description': 'در این بخش می‌توانید تاریخچه درخواست‌های تسویه خود را به همراه وضعیت آن‌ها مشاهده کنید.',
      'users_checkouts_list_description': 'در این بخش می‌توانید لیست درخواست‌های تسویه حساب کیف پول کاربران را مشاهده و در صورت نیاز آن‌ها را تایید یا رد کنید.',
      'bank_accounts': 'حساب‌های بانکی',
      'bank_accounts_description': 'در این بخش می‌توانید حساب‌های بانکی خود را برای واریز درخواست‌های تسویه تعریف کنید.',
      'wallet_credit': 'موجودی',
      'wallet_blocked_credit': 'مسدود شده',
      'add_account': 'ایجاد حساب',
      'edit_account': 'ویرایش حساب',
      'send_checkout_request': 'ارسال درخواست تسویه',
      'reply_message': 'متن پاسخ',
      'reply_message_if_needed': 'در صورت نیاز می‌توانید برای کاربر پیام خود را نیز ارسال کنید.',
      'confirm_checkout_request': 'تایید درخواست تسویه حساب',
      'reject_checkout_request': 'رد درخواست تسویه حساب',
      'first_add_bank_account': 'ابتدا حساب بانکی تعریف کنید'
    },
    'delivery_methods': {
      'delivery_methods_list': 'لیست روش‌های تحویل',
      'delivery_methods_list_description': 'در این بخش می‌توانید روش‌های تحویل سفارش را مدیریت و هزینه آن‌ها را به روزرسانی کنید.',
      'add_delivery_method': 'ایجاد روش تحویل',
      'add_delivery_method_description': 'در این بخش می‌توانید روش تحویل جدیدی برای سفارشات تعریف کنید.'
    },
    'delivery_timespans': {
      'delivery_dates': 'تاریخ‌های تحویل',
      'delivery_dates_description': 'در این بخش می‌توانید مشخص کنید که مشتری زمان تحویل سفارش خود را تا چند روز آینده می‌تواند مشخص کند.',
      'delivery_times': 'زمان‌های تحویل',
      'delivery_times_description': 'در این بخش می‌توانید بازه‌های زمانی تحویل سفارش در یک روز را مشخص کنید.'
    },
    'orders': {
      'order_products': 'محصولات سفارش داده شده',
      'order_delivery_method': 'نحوه تحویل سفارش',
      'order_delivery_address': 'آدرس تحویل سفارش',
      'order_address': 'آدرس سفارش',
      'order_delivery_time': 'زمان تحویل سفارش',
      'order_description': 'توضیحات سفارش',
      'new_address': 'آدرس جدید',
      'count': 'تعداد',
      'products_price': 'قیمت کالاها',
      'unit_price': 'قیمت واحد',
      'unit_onsale_price': 'قیمت واحد با تخفیف',
      'total_price': 'قیمت کل',
      'discount': 'تخفیف',
      'products_discount': 'تخفیف کالاها',
      'final_price': 'قیمت نهایی',
      'delivery_cost': 'هزینه ارسال',
      'price': 'مبلغ',
      'payment_price': 'مبلغ قابل پرداخت',
      'payment_method': 'نحوه پرداخت',
      'add_address': 'ایجاد آدرس جدید',
      'edit_address': 'ویرایش آدرس',
      'send_to_this_address': 'به این آدرس بفرست',
      'description_if_needed': 'در صورت نیاز، می‌توانید برای سفارش خود، در کادر زیر توضیحاتی اضافه کنید.',
      'order_submitted_successfully': 'سفارش شما با موفقیت ثبت شد',
      'order_number': 'شماره سفارش',
      'order_timestamp': 'تاریخ و ساعت سفارش',
      'payment_status': 'وضعیت پرداخت',
      'payment_reference_number': 'شماره پیگیری پرداخت',
      'status': {
        'being_sterilize':'دپو قبل',
        'being_sterilized':'در حال استریل',
        'degazin':'استریل شده (دگزین)',
        'degazins':'بعد از استریل',
        'raw_material': 'مواد اولیه',
        'injection': 'تزریق',
        'print': 'چاپ',
        'sterile':'استریل',
        'packing':'بسته بندی',
        'Assembly':'مونتاژ',
        'silicon': 'سیلیکون',
        'gasket_injection': 'تزریق گسکت',
        'plunger_injection': 'تزریق پلانجر',
        'barrel_injection': 'تزریق بارل',
        'print_barrel': 'چاپ بارل',
        'barrel_print_control': 'ردیابی فرآیند چاپ',
      },
      'edit_submitted_order_description': 'در این صفحه می‌توانید سفارش ثبت شده را ویرایش کنید',
      'users_orders_list_description': 'در این بخش می‌توانید لیست سفارشات کاربران را مشاهده کنید و اقدامات لازم را انجام دهید.',
      'order_control_page': 'در این بخش می‌توانید سفارشات را کنترل و اقدامات مورد نظر را انجام دهید',
      'print_order_control': 'در این بخش می‌توانید اقدامات سفارش را مشاهده و در صورت نیاز لیست خروجی  را پرینت نمایید',
      'collected_amounts_description': 'در صورتی که مقدار جمع‌آوری شده محصول کمتر از مقدار درخواستی باشد در این بخش می‌توانید ویرایش لازم را انجام دهید',
      'shipment_info': 'اطلاعات محموله ارسالی',
      'proceed_if_sure': 'در صورت اطمینان از تکمیل روند سفارش در این مرحله، می‌توانید نسبت به ارسال سفارش به مرحله بعد اقدام کنید.',
      'reply_message_if_needed': 'در صورت نیاز می‌توانید برای لغو سفارش توضیح خود را درج  کنید.',
      'proceed_order': 'اقدام سفارش',
      'cancel_order': 'لغو سفارش',
      'reply_message': 'متن پاسخ',
      'order_price': 'مبلغ سفارش',
      'search_filters': 'فیلترهای جستجو',
      'orders_list': 'لیست سفارش‌ها',
      'no_order_exists': 'سفارشی در این مرحله وجود ندارد',
      'store_message': 'پیام فروشگاه',
      'edit_order': 'ویرایش سفارش',
      //'cancel_order': 'لغو سفارش',
      'orders': 'سفارش‌ها',
      'customer_description': 'توضیحات مشتری',
      'submit_order': 'ثبت سفارش',
      'manage_order': 'مدیریت سفارشات',
      'submit_order_description': 'در این صفحه می‌توانید به صورت دستی برای یک مشتری سفارش ثبت کنید.',
      'edit_order_description': 'در این صفحه می‌توانید به صورت دستی سفارش را تغییر دهید.',
      'order_list_description': 'در این صفحه می‌توانید سفارشات را حذف یا ویرایش  کنید.',
      'customer': 'مشتری',
      'receiver': 'گیرنده',
      'products': 'محصولات',
      'product_description': ' اطلاعات محصول',
      'order_details': 'جزئیات سفارش',
      'pay_order': 'پرداخت سفارش',
      'send_order_to_status': 'سفارش برود به وضعیت ...',
      'customer_number': 'شماره مشتری',
      'customer_name': 'نام مشتری',
      'confirm': 'آیا از این اقدام اطمینان دارید؟'
    },
    'discounts': {
      'add_discount_code': 'ایجاد کد تخفیف',
      'add_discount_code_description': 'در این بخش می‌توانید کدهای تخفیف برای استفاده عموم مشتریان یا برای یک مشتری یا محصول خاص تعریف کنید',
      'edit_discount_code': 'ویرایش کد تخفیف',
      'edit_discount_code_description': 'در این صفحه می‌توانید اطلاعات مربوط به کد تخفیف را ویرایش کنید',
      'discount_codes_list': 'لیست کدهای تخفیف',
      'discount_codes_list_description': 'در این بخش می‌توانید کدهای تخفیف ایجاد شده را مدیریت کنید'
    },
    'invoices': {
      'type': {
        'buy': 'خرید',
        'return_buy': 'بازگشت خرید',
        'sell': 'فروش',
        'return_sell': 'بازگشت فروش',
        'pre_invoice': 'پیش فاکتور',
        'excluded': 'معدوم شده'
      },
      'submit_invoice': {
        'buy': 'ثبت فاکتور خرید',
        'return_buy': 'ثبت فاکتور بازگشت خرید',
        'sell': 'ثبت فاکتور فروش',
        'return_sell': 'ثبت فاکتور بازگشت فروش',
        'pre_invoice': 'ثبت پیش فاکتور',
        'excluded': 'ثبت فاکتور معدوم شده'
      },
      'submit_invoice_description': 'در این بخش می‌توانید اطلاعات فاکتور را وارد نمایید.',
      'invoice_information': 'اطلاعات فاکتور',
      'invoices_report': 'گزارش فاکتورها',
      'invoices_report_description': 'در این بخش می‌توانید در فاکتورهای ثبت شده جستجو کنید و در صورت نیاز آن‌ها را ویرایش یا حذف کنید',
      'edit_invoice': {
        'buy': 'ویرایش فاکتور خرید',
        'return_buy': 'ویرایش فاکتور بازگشت خرید',
        'sell': 'ویرایش فاکتور فروش',
        'return_sell': 'ویرایش فاکتور بازگشت فروش',
        'pre_invoice': 'ویرایش پیش فاکتور',
        'excluded': 'ویرایش فاکتور معدوم شده'
      },
      'edit_invoice_description': 'در این صفحه می‌توانید اطلاعات و محصولات فاکتور را ویرایش کنید',
      'products': 'محصولات',
      'invoice_details': 'جزئیات فاکتور',
      'invoice_number': 'شماره فاکتور',
      'invoice_reference_code': 'کد پیگیری فاکتور',
      'invoice_price': 'مبلغ فاکتور',
      'discount': 'تخفیف',
      'pay_invoice': 'پرداخت فاکتور',
      'invoice_products_list': 'لیست محصولات فاکتور'
    },
    'official_invoices':{
      'official_invoices_report': 'گزارش فاکتورهای رسمی',
      'official_invoices_report_description': 'در این بخش می‌توانید گزارشی از فاکتورهای رسمی ثبت شده تهیه کنید',
      'print_page_title': 'صورت حساب فروش کالا و خدمات',
      'invoice_number': 'شماره فاکتور',
      'date': 'تاریخ',
      'vendor_information': 'مشخصات فروشنده',
      'customer_information': 'مشخصات خریدار',
      'real_or_legal_person_title': 'نام شخص حقیقی / حقوقی',
      'economic_number': 'شماره اقتصادی',
      'registration_or_national_number': 'شماره ثبت / شماره ملی',
      '10_digits_postal_code': 'کدپستی 10 رقمی',
      'phone_or_fax_number': 'شماره تلفن / نمابر',
      'full_address': 'نشانی کامل',
      'sold_products_or_services_properties': 'مشخصات کالا یا خدمات مورد معامله',
      'product_code': 'کد کالا',
      'product_or_service_description': 'شرح کالا یا خدمات',
      'number_or_amount': 'تعداد / مقدار',
      'measurement_unit': 'واحد اندازه‌گیری',
      'unit_price': 'مبلغ واحد',
      'total_price': 'مبلغ کل',
      'discount_price': 'مبلغ تخفیف',
      'total_price_after_discount': 'مبلغ کل پس از تخفیف',
      'total_tolls_and_taxes': 'جمع مالیات و عوارض',
      'total_price_with_tolls_and_taxes': 'جمع مبلغ کل به علاوه جمع مالیات و عوارض',
      'total_summation': 'جمع کل',
      'price_in_words': 'مبلغ به حروف',
      'net_payable': 'خالص پرداختی',
      'sale_plan': 'شرایط و نحوه فروش',
      'description': 'توضیحات',
      'vendor_sign': 'امضای فروشنده',
      'customer_sign': 'امضای مشتری'
    },
    'payments':{
      'pay_by_person': 'دریافت از شخص',
      'receive_by_person': 'پرداخت به شخص',
      'payment_by_person_description': 'در این صفحه می‌توانید اطلاعات پرداخت یا دریافت وجه توسط اشخاص را ثبت نمایید',
      'payments_report': 'گزارش پرداخت‌ها',
      'payments_report_description': 'در این صفحه می‌توانید از پرداخت‌ها و دریافت‌های اشخاص گزارش تهیه کنید',
      'type': {
        'pay': 'دریافت از شخص',
        'receive': 'پرداخت به شخص',
        'pay_order': 'پرداخت سفارش',
        'pay_order_on_delivery': 'پرداخت سفارش در هنگام تحویل',
        'cancel_order': 'لغو سفارش',
        'return_extra_ordered_amount': 'بازگشت مقدار اضافه سفارش محصول',
        'submit_invoice': 'ثبت فاکتور'
      }
    },
    'print' : {
      'print_delivered' :'برگ خروج از انبار',
      'senator_title' :'برنا طب ایساتیس'
    },
    'reports': {
      'buy_and_sell_report': 'گزارش خرید و فروش محصولات',
      'buy_and_sell_report_description': 'در این صفحه می‌توانید از مقدار خرید یا فروش یک یا چند محصول در یک بازه زمانی گزارش تهیه کنید',
      'buy_report': 'گزارش خرید',
      'order_control': ' کنترل سفارش',
      'sell_report': 'گزارش فروش',
      'total_amount': 'مقدار کل',
      'total_price': 'قیمت کل',
      'users_logs_report': 'گزارش لاگ کاربران',
      'users_logs_report_description': 'در این صفحه می‌توانید از عملکرد کاربران در استفاده از سایت گزارش تهیه کنید'
    },
    'balances_report': {
      'debtor': 'بدهکار',
      'creditor': 'بستانکار',
    },
    'site_settings': {
      'add_slide': 'ایجاد اسلاید',
      'add_slide_description': 'در این بخش می‌توانید اسلاید جدیدی برای نمایش در بالای صفحه اصلی سایت ایجاد نمایید',
      'slides_list': 'لیست اسلایدها',
      'slides_list_description': 'در این بخش می‌توانید لیست اسلایدهای صفحه اصلی سایت را مشاهده کنید و در صورت لزوم آن‌ها را حذف نمایید یا ترتیب نمایش آن‌ها را تغییر دهید',
      'manage_main_page_banners': 'مدیریت بنرهای صفحه اصلی',
      'manage_main_page_banners_description': 'در این صفحه می‌توانید بنرهای صفحه اصلی سایت را جایگزین کنید',
      'first_banner': 'بنر اول',
      'second_banner': 'بنر دوم'
    }
  },
  'notes': {
    'currently_logged_in': 'کاربر عزیز، شما در حال حاضر وارد حساب کاربری خود شده‌اید',
    'successfully_uploaded_press_save': 'عکس آپلود شده است. برای تکمیل فرآیند بر روی دکمه ذخیره کلیک کنید.',
    'successfully_select_product': 'محصول انتخاب شده است',
    'successfully_uploaded_press_edit': 'عکس آپلود شده است. برای تکمیل فرآیند بر روی دکمه ویرایش کلیک کنید.',
    'redirecting_to_product_page': 'در حال انتقال به صفحه محصول...'
  },
  'alerts': {
    'titles': {
      'warning': 'هشدار',
      'danger': 'اخطار'
    },
    'messages': {
      'confirm_delete': 'آیا برای حذف اطمینان دارید؟ این عملیات غیرقابل بازگشت می‌باشد!',
      'confirm_ban_user': 'آیا برای مسدود کردن کاربر اطمینان دارید؟',
      'confirm_unban_user': 'آیا برای رفع مسدودی کاربر اطمینان دارید؟',
      'confirm_publish_product': 'آیا برای انتشار محصول اطمینان دارید؟',
      'confirm_unpublish_product': 'آیا برای عدم انتشار محصول اطمینان دارید؟',
      'confirm_store_and_publish_product': 'آیا برای ذخیره و انتشار محصول اطمینان دارید؟',
      'confirm_cancel_order': 'آیا برای لغو سفارش اطمینان دارید؟ این عملیات غیرقابل بازگشت می‌باشد!',
      'confirm_confirm_checkout': 'آیا برای تایید درخواست کاربر اطمینان دارید؟',
      'confirm_reject_checkout': 'آیا برای رد درخواست کاربر اطمینان دارید؟',
      'confirm_proceed_selected_orders': 'آیا برای اقدام سفارشات انتخاب شده اطمینان دارید؟ این عملیات غیرقابل بازگشت می‌باشد',
      'confirm_issue_official_invoice': 'آیا برای صدور فاکتور رسمی اطمینان دارید؟ در صورت صدور فاکتور رسمی، حذف آن ممکن است به سادگی قابل انجام نباشد'
    }
  },
  'toasts': {
    'success': {
      'product_link_copied': 'لینک محصول کپی شد'
    },
    'danger': {
      'select_person': 'شخص را انتخاب کنید'
    }
  },
  'image_alts': {
    'background': 'پس زمینه',
    'logo': 'لوگو',
    'shopping_cart': 'سبد خرید',
    'avatar': 'آواتار',
    'phone': 'تلفن',
    'license': 'مجوز',
    'google_play': 'گوگل پلی',
    'bazar': 'بازار',
    'aparat': 'آپارات',
    'instagram': 'اینستاگرام',
    'telegram': 'تلگرام',
    'blogger': 'بلاگر',
    'home_slide': 'اسلاید صفحه اصلی',
    'home_banner': 'بنر صفحه اصلی',
    'support': 'پشتیبانی',
    'delivery': 'ارسال',
    'payment': 'پرداخت',
    'special_sale': 'فروش ویژه',
    'ad': 'تبلیغ',
    'add': 'افزودن',
    'like': 'پسندیدم',
    'dislike': 'نپسندیدم',
    'report': 'گزارش',
    'category_thumbnail': 'عکس بندانگشتی دسته‌بندی',
    'product_thumbnail': 'عکس بندانگشتی محصول',
    'post_thumbnail': 'عکس بندانگشتی پست',
    'order': 'مرتب سازی',
    'empty_cart': 'سبد خرید خالی',
    'login': 'ورود',
    'in_person': 'حضوری',
    'header_image': 'عکس هدر',
    'image': 'عکس',
    'user': 'کاربر',
    'wallet': 'کیف پول',
    'my_orders': 'سفارش‌های من',
    'sign_out': 'خروج',
    'orders_empty': 'عدم وجود سفارش',
    'user_avatar': 'آواتار کاربر',
    'product_image': 'عکس محصول',
    'payment_icon': 'آیکون پرداخت',
    'refresh': 'به‌روزرسانی',
    'delete': 'حذف',
    'list': 'لیست',
    'banner': 'بنر',
    'menu': ' نمایش منو'
  }
}
